.blog-form {
    width: 80%;
    height: auto;
    margin-top: 5%;
    margin-left: 10%;
    border: 2px solid lightgray;
    box-shadow: 0 2px 5px 0 lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    text-transform: uppercase;
    text-align: center;
}

.blog-post {
    color: #525252;
    font-size: 12px;
}