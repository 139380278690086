.about-form {
    width: 80%;
    height: auto;
    margin-top: 5%;
    margin-left: 10%;
    border: 2px solid lightgray;
    box-shadow: 0 2px 5px 0 lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    text-transform: uppercase;
    text-align: center;
  }

  .about-us {
    color: #525252;
    font-size: 12px;
  }

  div .zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    }
    @keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    } 

    .email-contact a {
      color: #525252;
      font-size: 12px;
    }

    .email-contact a:hover {
      color: lawngreen;
      text-shadow: 1px 0 gray;
      text-decoration: underline;
    }