div .box-container {
    width: 80%;
    height: auto;
    margin-top: 8%;
    margin-left: 10%;
    border: 2px solid white;
    box-shadow: 0 2px 5px 0 lightgrey;

}

div .box-container a {
    color: #525252;
    display: flex;
    flex-direction: row;
    justify-content: center;
}