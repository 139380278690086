.navbar {
  width: 100%;
  height: 80px;
  background: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar > span {
  color: white;
  text-decoration: none;
  padding: 15px;
  font-size: 20px;
  text-transform: uppercase;
}

.navbar > span:hover {
  color: lawngreen;
  cursor: pointer;
}

a,
a:visited {
  color: white;
  text-decoration: none;
  padding: 12px;
  font-size: 20px;
  text-transform: uppercase;
}

a:hover {
  color: lawngreen;
}

span {
  color: white;
  font-size: 20px;
  text-transform: uppercase;
}

nav div span:hover {
  color: lawngreen;
}

nav div img {
  width: 200px;
  height: 185px;
  margin-left: 180px;
  margin-top: 10px;
  /* left: 250px; */
  /* top: 5px; */
  /* position: relative; */
}

.nav-links {
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.p-dropdown {
  position: relative;
  cursor: pointer;
}

.p-dropdown:hover {
  color: lawngreen;
}

.port-dropdown {
  position: absolute;
  height: auto;
  background: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 51px;
  right: -21px;
  box-shadow: 1px 1px 3px 2px rgba(25, 25, 25, 0.3);
  animation: fadeIn 200ms;
  z-index: 1;
}

.port-dropdown > a {
  color: #525252;
  width: 150px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  z-index: 1;
}

.port-dropdown > a:hover {
  color: lawngreen;
  cursor: pointer;
  background: black;
}

.divider {
  width: 100%;
  border-bottom: 1px solid lightgray;
}

@keyframes slidedown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.desktop-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart {
  color: white;
  margin-right: 35px;
}

.cart:hover {
  color: lawngreen;
}

.hamburger:hover {
  cursor: pointer;
  color: lawngreen;
}

.hamburger {
  color: white;
  display: block;
  overflow: hidden;
}

.sidebar {
  position: absolute;
  right: 0;
  top: 80px;
}

.sidebar > a {
  color: #525252;
  background: #ebebeb;
  font-size: 15px;
  height: 12px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgray;
  box-shadow: 1px 1px 3px 1px rgba(25, 25, 25, 0.3);
  animation: slidedown 200ms;
}

.sidebar > a:hover {
  background: black;
  color: lawngreen;
}

.mobile-icons {
  display: none;
}

.mobile-sidebar {
  display: none;
  
}

.mobile-sidebar a {
  color: #525252;
  background: #ebebeb;
}

.mobile-sidebar a:hover {
  color: lawngreen;
  background: black;
}

/* Tablet View*/
@media only screen and (max-width: 768px) {
  nav div img {
    margin-left: 20px;
    margin-top: 15px;
  }
  .nav-links {
    text-align: center;
    width: 60%;
    letter-spacing: -2px;
    margin-right: 10px;
    display: flex;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1;
  }

  div .desktop-icons {
    margin-right: 80px;
    display: flex;
    flex-direction: row;
  }
}

/* iPhone 7/8 Plus View */
@media only screen and (max-width: 414px) {
  nav div img {
    margin-left: 25px;
    margin-top: 15px;
  }
  .nav-links {
    text-align: center;
    width: 100%;
    display: none;
  }

  .sidebar {
    display: none;
  }

  div .mobile-sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 235px;
    margin-top: 158px;
    justify-content: flex-end;
    z-index: 1;
    position: absolute;
    background: #ebebeb;

  }

  .mobile-icons {
    width: 35%;
    margin-left: -20px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}

/* Galaxy 5 View */
@media only screen and (max-width: 360px) {
  nav div img {
    margin-left: 20px;
    margin-top: 15px;
  }
  .nav-links {
    text-align: center;
    width: 100%;
    display: none;
  }

  .sidebar {
    display: none;
  }

  div .mobile-sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 180px;
    margin-top: 158px;
    justify-content: flex-end;
    z-index: 1;
    position: absolute;
    background: #ebebeb;
  }

  .mobile-icons {
    width: 40%;
    margin-left: -30px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
