.pricing-form {
  width: 80%;
  height: auto;
  margin-top: 3%;
  margin-left: 8%;
  padding: 15px;
  right: 50px;
  border: 2px solid lightgray;
  box-shadow: 0 2px 5px 0 lightgrey;
  font-family: sans-serif;
  font-size: 16px;
}

.pricing {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
