.cart-form {
  width: 80%;
  height: auto;
  margin-top: 5%;
  margin-left: 8%;
  padding: 15px;
  right: 50px;
  border: 2px solid lightgray;
  box-shadow: 0 2px 5px 0 lightgrey;
  font-family: sans-serif;
  font-size: 15px;
  /* text-align: center; */

}

.break {
  border-bottom: 1px solid lightgray;
}

.shoppingcart {
  margin-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div .description {
  font-size: 16px;
  font-weight: bold;
}

form {
  display: inline-block;
}

.shopping-cart-form {
    width: 80%;
    height: auto;
    margin-top: 5%;
    margin-left: 8%;
    padding: 15px;
    right: 50px;
    border: 2px solid lightgray;
    font-family: sans-serif;
    font-size: 15px;
    text-align: center;
  
}

.checkout-desc {
  font-size: 12px;
  color: gray;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu a {
  color: #525252;
  font-size: 14px;
}