.login {
    font-family: sans-serif;
}

.login-form {
    width: 80%;
    height: 60vh;
    margin-top: 5%;
    margin-left: 10%;
    border: 2px solid lightgray;
    box-shadow: 0 2px 5px 0 lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

label.email-input {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}

label.password-input {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.register-link a {
    color: #525252;
    font-size: 10px;
}

.register-link {
    padding: 20px
}

form {
    display: inline-block;
    text-align: center;
}