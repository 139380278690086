.user-form {
    width: 80%;
    height: auto;
    margin-top: 5%;
    margin-left: 10%;
    padding: 1%;
    border: 2px solid lightgray;
    box-shadow: 0 2px 5px 0 lightgrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu a {
    color: #525252;
    font-size: 14px;
}

.user-form > .menu > .message-box {
    width: 40vw;
    height: 40vh;
    border: 1px solid lightgray

}

div .update-profile {
    display: flex;
    text-align: center;
    justify-content: space-around;
    align-items: baseline;
}

div .upate-profile {
    font-weight: 500;
}

::placeholder {
    text-align: center;
    color: gray;
}

div .click-upload {
    font-size: 12px;
    color: gray;
}